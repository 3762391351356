// import Vue from 'vue';
import { 
	IFilterOption,
	IFiltersState
} from '@/types';

const state: IFiltersState = {
	conditions: [],
	bodies: [],
	certified: [],
	fuels: [],
	locations: [],
	makes: [],
	models: [],
	transmissions: [],
	priceMin: 0,
	priceMax: 0,
	yearMin: 0,
	yearMax: 0
};

const mutations = {
	setConditions(state: IFiltersState, payload: Array<string>){
		state.conditions = payload;
	},
	setBodies(state: IFiltersState, payload: Array<string>){
		state.bodies = payload;
	},
	setCertified(state: IFiltersState, payload: Array<string>){
		state.certified = payload;
	},
	setFuels(state: IFiltersState, payload: Array<string>){
		state.fuels = payload;
	},
	setLocations(state: IFiltersState, payload: Array<number>){
		state.locations = payload;
	},
	setMakes(state: IFiltersState, payload: Array<string>){
		state.makes = payload;
	},
	setModels(state: IFiltersState, payload: Array<string>){
		state.models = payload;
	},
	setTransmissions(state: IFiltersState, payload: Array<string>){
		state.transmissions = payload;
	},
	setPrice(state: IFiltersState, payload: Array<number>){
		if (payload.length == 2){
			state.priceMin = payload[0];
			state.priceMax = payload[1];
		} else console.log('error setting price', payload);
	},
	setYear(state: IFiltersState, payload: Array<number>){
		if (payload.length == 2){
			state.yearMin = payload[0];
			state.yearMax = payload[1];
		} else console.log('error setting year', payload);
	},
	setFilters(state: IFiltersState, payload: IFiltersState){
		for (const o in payload){
			(state as any)[o] = (payload as any)[o];
		}
	},
};
export default {
	namespaced: true,
	state, 
	mutations
};