export enum brands {
	toyota = 1, 
	mazda = 2, 
	mg = 3
}
export enum ESortOptions {
	PriceLowToHigh = 1,
	PriceHighToLow = 2,
	YearLowToHigh = 3,
	YearHighToLow = 4
}
export enum EFilterQueryStringParameters {
	priceMin = "cMin", 
	priceMax = "cMax",
	yearMin = "yMin",
	yearMax = "yMax",
	locations = "locations",
	makes = "makes",
	certified = "certified",
	models = "models",
	body="body", 
	fuel="fuel", 
	transmission="transmission",
	sort="sort",
	conditions="condition"
}
export enum EFilterNames {
	condition = "condition", 
	location = "location", 
	make = "make", 
	certified = "certified", 
	model = "model",
	body = "body", 
	fuel = "fuel",
	transmission = "transmission", 
	price = "price", 
	year = "year"
}
export enum ECtas {
	requestAVideoButton = "requestvideo",
	testDriveButton = "testdrive",
	enquireButton = "enquire",
	reserveButton = "reserve",
	certifiedText = "certified",
	quickLook = "quicklook"
}
export enum EStockCategoryId {
	new = 1,
	used = 2,
	demo = 3
}
export enum EEnquiryTypeId {
	enquiryNew = 6,
	enquiryUsed = 7,
	enquiryDemo = 25,

	testDriveNew = 1,
	testDriveUsed = 2,
	testDriveDemo = 26,

	requestVideo = 27
}