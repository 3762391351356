import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import RouterApp from '@/RouterApp.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*', 
    name: 'All',
    component: RouterApp
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
