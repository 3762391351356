 
export const dataDomain = (window.location.host.match(/\.self|localhost|ac3\.tbone\.com\.au/g)) ? 'https://data.staging.ac3.tbone.com.au' : 'https://data.autoconsole.com.au';
export const apiDomain = (window.location.host.match(/\.self|localhost|ac3\.tbone\.com\.au/g)) ? 'https://data.staging.ac3.tbone.com.au' : 'https://data.autoconsole.com.au'
// export const apiDomain = 'http://localhost:53898';

export const noImageMessage = 'Just arrived'
export const savedVehiclesUrl = (window as any).uvFilterInit.SavedVehiclesUrl
export const uvHomeUrl = (window as any).uvFilterInit.NewSearchUrl;
export const tilesPerPage = 48;
export const authEndpoint = apiDomain + '/oauth/token';

export const endpointPrefix = location.href.includes("localhost:") ? "http://chatswood.self" : "";
export const enquiryEndpoint = endpointPrefix + '/api/IndividualVehicleEnquiry';
export const testdriveEndpoint = endpointPrefix + '/api/IndividualTestDriveEnquiry';
export const vehiclesEndpoint = apiDomain + '/api/usedvehicle';
export const carsalesLeadsEndpoint = endpointPrefix + '/api/CarsalesEnquiry';

export const defaultHeaders = (bearerToken: string): HeadersInit => {
	return { 
		Authorization: `Bearer ${bearerToken}`,
		'Content-Type': 'application/json'
	}
};