import { 
	IFilterPostObject, 
	IFiltersState, 
	WindowFilterInitListing, 
	IFilterOption, 
	ILocation, 
	ISavedPostObject
} from '@/types';

export const getYearFormatPre = (val: number, min: number): string => {
	return `${min == val ? 'Pre-' + (val+1) : val}`;
};
export const getYearFormat = (val: number): string => `${val}`;
export const getPriceFormat = (val: number): string => `$${commify(val)}`;
export const commify = (value: number): string => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const toTitleCase = (value: string): string => {
    return value.toString().replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
};
export function sendFbqEvent(action: any, type: any, obj: any): void {
    let blank;
    const fbq =
        (window as any).fbq || 
        function(a: any, b: any, c: any) {
            console.log(c);
        };
    switch (type) {
        default: blank = {
            content_type: 'vehicle',
            content_ids: [],
            currency: 'AUD'
        };
        break;
    }
    fbq(action, type, Object.assign(blank, obj));
}
export function getRegosPostObject(Regos: Array<string>, scid: Array<number>): ISavedPostObject {
	return {
		sci: scid || [],
		Regos: Regos,
		ind: -1
	}
}
export function getFilterObject(filters: IFiltersState | undefined, sort: number, index: number): IFilterPostObject {
	const f = filters || getInitialFilters();
	// tc only one value supported at the moment.
	const certArray = f.certified.filter((o: IFilterOption) => o.Selected).map((o: IFilterOption) => o.Item.toLowerCase());
	const tc = certArray.length ? certArray[0] === 'yes' ? true : certArray[0] === 'no' ? false : null : null;
	
	const mak = f.makes.filter((o: IFilterOption) => o.Selected).map((o: IFilterOption) => o.Item.toLowerCase());
	const mod = f.models.filter((o: IFilterOption) => o.Selected).map((o: IFilterOption) => o.Item.toLowerCase());
	const tra = f.transmissions.filter((o: IFilterOption) => o.Selected).map((o: IFilterOption) => o.Item);
	const bod = f.bodies.filter((o: IFilterOption) => o.Selected).map((o: IFilterOption) => o.Item);
	const fue = f.fuels.filter((o: IFilterOption) => o.Selected).map((o: IFilterOption) => o.Item);
	const lid = f.locations.filter((o: IFilterOption) => o.Selected).map((o: ILocation) => o.LocationId);
	const sci = f.conditions.filter((o: IFilterOption) => o.Selected).map((o: ILocation) => o.LocationId);
	// const oci = (window as any).uvFilterInit.FilterConditions.map((o: ILocation) => o.LocationId);
	
	const hasPreYearValue = (window as any).uvFilterInit.HasPreYearValue;
	const preYearValue = (window as any).uvFilterInit.YearMin - 1;
	const shouldSendZero = f.yearMin == preYearValue && hasPreYearValue;

	return {
		obi: sort,
		pmi: f.priceMin,
		pma: f.priceMax,
		ymi: shouldSendZero ? 0 : f.yearMin,
		yma: f.yearMax,
		mak,
		mod,
		tra,
		bod,
		fue,
		tc,
		ind: index,
		lid, 
		sci, 
		// oci
	}
}
export function getInitialFilters(): IFiltersState {
	const f: WindowFilterInitListing = (window as any).uvFilterInit;
	
	return {
		conditions: f.FilterConditions || [],
		bodies: f.FilterBodies || [],
		certified: f.FilterCertified || [],
		fuels: f.FilterFuel || [],
		locations: f.FilterLocations || [],
		makes: f.FilterMakes || [],
		models: f.FilterModels || [],
		transmissions: f.FilterTransmissions || [],
		priceMin: f.PriceMin,
		priceMax: f.PriceMax,
		yearMin: f.YearMin,
		yearMax: f.YearMax
	};
}
export function getMakeFromId(id: number): string {
	switch (id){
		case 1: {
			return "Toyota";
		}
		case 2: {
			return "Mazda";
		}
		case 3: {
			return "MG"
		}
		default: {
			return "";
		}
	}
}